
export default {
  namespaced: true,
  state: {
    testList: [], // 题目数据
    random: ''
  },
  mutations: {
    settest (state, item) {
      state.testList = item
    },
    setrandom (state, item) {
      state.random = item
    }
  },
  getters: {}
}
