// import store from '../store'
import Vue from 'vue'
// 创建WebSocket实例

// 随机生成6位
function generateRandomNumber () {
  return Math.floor(100000 + Math.random() * 900000)
}
const res = generateRandomNumber()
// 存本地
localStorage.setItem('random', res)
const ws = new WebSocket(`wss://hudong.ityitiaolong.com/chat/exam/${res}/`)
Vue.prototype.$ws = ws
