
import axios from 'axios'
import { Message } from 'element-ui'

// 创建axios实例
const request = axios.create({
  baseURL: 'https://hudong.ityitiaolong.com',
  timeout: 5000
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么

  return config
}, function (error) {
  // 对请求错误做些什么

  Message.error('网络异常，请稍后再试')
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  return response.data // 解套
}, function (error) {
  // 对响应错误做点什么
  Message.error('服务器请求失败，请联系管理员')
  return Promise.reject(error)
})

export default request
