import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 配置一级路由
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/topic',
    component: () => import('@/views/TopicPrese')
  },
  {
    path: '/end',
    component: () => import('@/views/EndPage')
  }
]
const router = new VueRouter({
  routes
})

// 修复 重复渲染路由 报错
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
